const navBar = document.querySelector('.nav-bar')
const aboutUs= document.querySelector('.about-us')
const subMenu=document.querySelector('.submenu-container')
const sliderContainer=document.querySelector('.slider-container')
const slider=document.querySelectorAll('.slider')
const btnContainer=document.querySelector('.btn-container')
const arrowBtnRight= document.querySelector('.btn-right')
const arrowBtnLeft= document.querySelector('.btn-left')
const nav =document.querySelector('.nav')
const mobileNav= document.querySelector('.mobile-nav')
const menuBtn= document.querySelector('.menu')
const CaroselContentContainer=document.querySelector('.content-container')
const caroselContent=document.querySelectorAll('.carosel-content')
const caroselBtnLt=document.querySelector('.carosel-btn-lt')
const caroselBtnRt=document.querySelector('.carosel-btn-rt')
const abt=document.querySelector('#aboutssa')
const footCont=document.querySelectorAll('.footer-content')
const memberStates=document.querySelector('.social-banner-container')

footCont.forEach(el=>{
    el.addEventListener('click',e=>{
        if(! e.target.classList.contains('heading')) return;

        // if(!e.target.classList.contains('change-arrow')) return;
          
       
        const sammy=e.target.nextElementSibling
        sammy.classList.toggle('show-inner')
        sammy.classList.contains('show-inner')?  e.target.firstElementChild.innerHTML='&#8710;':e.target.firstElementChild.innerHTML='&#8711;'

        if(!e.target.classList.contains('change-arrow')) return;
  
    })

})
const states=[{flag: 'https://wapor-ssa.org/src/uploads/kenya.png',
    name: 'kenya'},
    {flag: 'https://wapor-ssa.org/src/uploads/nigeria.png',
    name: 'Nigeria'},
    {flag: 'https://wapor-ssa.org/src/uploads/ghana.png',
    name: 'Ghana'},
    {flag: 'https://wapor-ssa.org/src/uploads/senegal.png',
    name: 'Senegal'},
    {flag: 'https://wapor-ssa.org/src/uploads/ivory-coast.png',
    name: 'Ivory Coast'},
    {flag: 'https://wapor-ssa.org/src/uploads/guinea.png',
    name: 'Guinea'},
    {flag: 'https://wapor-ssa.org/src/uploads/niger.png',
    name: 'Niger'},
    {flag: 'https://wapor-ssa.org/src/uploads/togo.png',
    name: 'Togo'},
    {flag: 'https://wapor-ssa.org/src/uploads/burkina-faso.png',
    name: 'Burkina Faso'},
    {flag: 'https://wapor-ssa.org/src/uploads/uganda.png',
    name: 'Uganda'},
    {flag: 'https://wapor-ssa.org/src/uploads/tanzania.png',
    name: 'Tanzania'},
    {flag: 'https://wapor-ssa.org/src/uploads/ethiopia.png',
    name: 'Ethiopia'},
    {flag: 'https://wapor-ssa.org/src/uploads/south-sudan.png',
    name: 'South Sudan'},
    {flag: 'https://wapor-ssa.org/src/uploads/sudan.png',
    name: 'Sudan'},
    {flag: 'https://wapor-ssa.org/src/uploads/dr-congo.png',
    name: 'DR Congo'},
    {flag: 'https://wapor-ssa.org/src/uploads/cameroon.png',
    name: 'Cameroon'},
    {flag: 'https://wapor-ssa.org/src/uploads/south-africa.png',
    name: 'South Africa'},
    {flag: 'https://wapor-ssa.org/src/uploads/eswatini.png',
    name: 'Eswatini'}
]


states.sort()

setTimeout(()=>{
    memberStates.textContent="";
    states.forEach(country=>{
    const html= 
    `
    <li ><img src="${country.flag}" title="${country.name}"><p>${country.name}</p>
    </li>
  
    `
    memberStates.insertAdjacentHTML('afterbegin',html)

  })  
},4000)

   

// const getCountry= async function(){
//     try{
//        const res= await fetch('https://restcountries.com/v3.1/region/Africa')

//        console.log(res)

//        const data= await res.json();
//        console.log(data)

//     }catch(err){
//         console.log(err.message)
//     }
   
// }


// const [flag,name] =states;

// states.forEach(el=>{
//    const html=`<h2 style="text-align: center ;">Member States</h2>
//     <ul style="list-style-type: none;margin: 10px 60px;">
//       <li style="margin: 2px 2px;"><img ${flag}" style="width:60px;height:60px;border-radius:50% ;"> <p style="float: right;">${name}}<p></li>
//       </ul>
//       `
    
//       memberStates.insertAdjacentHTML('afterbegin',html)
// })

// const membershipLink=document.querySelector('.membership-link')

// membershipLink.addEventListener('click',function(e){
// const id =window.location.href.split('.')[1].slice(0,3)
// // console.log(me)
// // const id =e.target.getAttribute('href')
// // if(!id) return;
// // console.log(id)
// document.querySelector(id).scrollIntoView({behavior:'smooth'})
// })

// mobile menu


menuBtn.addEventListener('click',function(e){
e.preventDefault()
// nav.classList.remove('show')
nav.classList.toggle('showmenu')
})


abt.addEventListener('click',function(e){
    e.preventDefault();
   
    const d =e.target.getAttribute('href')
    if(!d) return
    document.querySelector(`${d}`).scrollIntoView({behavior: "smooth"})
    
})

const myAboutUs=function(entries,observer){
const [entry]=entries
if(!entry.isIntersecting) return  ;
aboutUs.classList.remove('hide-content')
aboutUs.classList.add('show-content');  
 observer.unobserve(entry.target)
}

// slider
let currSlide=0;
let maxLen=slider.length-1;
let maxLenCarosel= caroselContent.length-1

// go to a specific slide
const goTo=function(slide,el){
    el.forEach((s,i)=>{
        
        s.style.transform=`translateX(${100*(i-slide)}%) `
        
        
    })
}

setInterval(function(){
    goTo(0,slider)
    if(currSlide===maxLen){
        currSlide=0
    }else{
        currSlide++
    }

goTo(currSlide,slider)

},5000)

arrowBtnRight.addEventListener('click',function(){
    if(currSlide===maxLen){
        currSlide=0
    }else{
        currSlide++
    }

goTo(currSlide,slider)

})

arrowBtnLeft.addEventListener('click',function(){
    if(currSlide===0){
        currSlide=maxLen
    }else{
        currSlide--
    }

    goTo(currSlide,slider)
})


caroselBtnRt.addEventListener('click',function(){
    
    if(currSlide>=maxLenCarosel){
        currSlide=0
    }else{
        currSlide++
    }
        goTo(currSlide,caroselContent)
})

caroselBtnLt.addEventListener('click',function(){
    
    if(currSlide===0){
        currSlide=maxLenCarosel
    }else{
        currSlide--
    }

    goTo(currSlide,caroselContent)
})


const intersectAbout= new IntersectionObserver(myAboutUs,{
    root: null,
    threshold: 0.3,
    // rootMarhgin:100
})

intersectAbout.observe(aboutUs)

